import { Link, graphql, navigate } from "gatsby";
import React, {useState} from 'react';
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/NonUkResidents/HCP/footer'; 
import {useDispatch} from "react-redux"
import Header from '../../../components/NonUkResidents/HCP/header';
import { Icon } from 'react-icons-kit'
import {ic_close} from 'react-icons-kit/md/ic_close'
import {showNonUkResidentsHcpDisclaimerPopup} from "../../../actions/nonUkResidents/hcp"
import {addDisclaimersToCmsLinks, capitalizeFirstLetter, isNullUndefined, richTextOptions} from "../../../util/index" 
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultNonUkResidentsHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import queryString from "query-string"
import SEO from "../../../components/seo"
import TemplateWrapper from "../../../components/TemplateWrapper"
import Auth from "@aws-amplify/auth";
import { showNonUkHcpAuthFormPopup } from "../../../actions/nonUkResidents/hcp/forms";
import {enforceLoggedInCheckForGermanPrescribers, enforceLoggedInCheckForGermanPrescribersEfmody} from "../../../controllers/nonUkResidents/hcp/doccheck"

export const pageQueryVideoPdfTemplateNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulVideoPdfTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
          spaceId
          slug
          title
          bannerImage {
            file {
              url
            }
          }
        
          bodyText {
            json
          }

          flag {
            file {
              url
            }
          }
          pdf {
            file {
              url
              fileName
            }
          }
          video {
            file {
              url
            }
          }
          videoPoster {
          file {
            url
          }
        }
          videoSectionSideText {
            json
          }
          videoSectionTopText {
            json
          }
          parentMenu {
            title
            slug
          }
        }
    }
`


const VideoPdfTemplatePageNonUkHcp = (props) => {

    const isItalianPrescribers = props.data.contentfulVideoPdfTemplate.slug === "information-for-italian-prescribers";
    const isGermanPrescribers = props.data.contentfulVideoPdfTemplate.slug === "information-for-german-prescribers";
    const isGermanPrescribersEfmody = props.data.contentfulVideoPdfTemplate.slug === "information-for-german-prescribers-for-efmody";

    const [isActive, setIsActive] = useState(false)
    
    const dispatch = useDispatch();

    const {videoPoster, spaceId, slug, title, bannerImage, bodyText, flag, pdf, video, videoSectionSideText, videoSectionTopText, parentMenu} = props.data.contentfulVideoPdfTemplate;

    let richTextBody = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)
    let richTextVideoSide = documentToReactComponents(isNullUndefined(videoSectionSideText) ? videoSectionSideText : videoSectionSideText.json, richTextOptions)
    let richTextVideoTop = documentToReactComponents(isNullUndefined(videoSectionTopText) ? videoSectionTopText : videoSectionTopText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richTextBody)
    let videoSideHtml = ReactDOMServer.renderToStaticMarkup(richTextVideoSide)
    let videoTopHtml = ReactDOMServer.renderToStaticMarkup(richTextVideoTop)

    const openAuthForm = (url, username) => {
      let actionConfig = {
        show: true,
        okLabel: "Ok",
        url: url,
        username: username || "",
        path: props.location.pathname,
        closeLabel: "Cancel",
        okCallback: () => {},
        closeCallback: () => {}
      }
      dispatch(showNonUkHcpAuthFormPopup(actionConfig))
    }

    const showFurtherResources = (url) => {
      navigate(url);
    }

    const viewFurtherResources = async (e, username) => {
      if(e){
        e.preventDefault();
      }

      let furtherResourcesUrl = "/NonUkResidents/HCP/further-resources-for-italian-prescribers";
      let loggedIn = null;

      try {
        loggedIn = await Auth.currentAuthenticatedUser();
      }catch(e){}

      if(loggedIn){
        showFurtherResources(furtherResourcesUrl);
      }else{
        openAuthForm(furtherResourcesUrl, username);
      }
    }
  
    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);
    let [revealPage, setRevealPage] = React.useState(!isGermanPrescribers && !isGermanPrescribersEfmody);

    React.useEffect(() => {
        let {veevaDisclaimer, username, code} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }

        if(isItalianPrescribers){
          if(username){
            viewFurtherResources(null, username);
          }
          return;
        }

        if(isGermanPrescribers){
          (async () => {
            let shouldRevealPageContent = await enforceLoggedInCheckForGermanPrescribers(code);
            setRevealPage(shouldRevealPageContent);
          })()
          return;
        }

        if(isGermanPrescribersEfmody){
          (async () => {
            let shouldRevealPageContent = await enforceLoggedInCheckForGermanPrescribersEfmody(code);
            setRevealPage(shouldRevealPageContent);
          })()
          return;
        }
    }, []);
  
    if((isGermanPrescribers || isGermanPrescribersEfmody) && !revealPage){
      return <React.Fragment />
    }

    return ( 
        <TemplateWrapper>
          <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | HCP`} />
        
      
            <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`, backgroundPositionY: 'bottom'}}>
            <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                
                               <h1 className="">
                                   {title}
                               </h1>
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to={'/NonUkResidents/HCP/home'}>Home</Link></li>
                                    <li className="breadcrumb-item">
                                    <Link to={`/NonUkResidents/HCP/${parentMenu.slug}`}>
                                        {parentMenu.title}
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {title}
                                    </li>
                                </ol>
                               </div>
                               </div>
                        </div>
            </div>
            <div className={styles.video_content} style={{minHeight: 'calc(100vh - 587px)'}}>
                    <div className="container pt-2 pb-2">
       
                     
                    <div className="row ">
                  
                    <div className="col-12 col-sm-12 col-md-12 text-left">
                      <div className={styles.VideoContent_BodyText}>
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    </div> 

                    {isItalianPrescribers && (<div className={"col-12 mt-4 mb-2"}>
                        <a style={{cursor: "pointer"}} onClick={(e) => viewFurtherResources(e)}>Click here to view further available resources</a>
                    </div>)}
                    </div>

                </div>
                    </div>
                    <div className={(!isNullUndefined(videoSectionTopText) || !isNullUndefined(video) || !isNullUndefined(videoSectionSideText)) ? styles.video_section : ""}>
                        <div className="container">
                        {!isNullUndefined(videoSectionTopText) && ReactHtmlParser(videoTopHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                        
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                           
                            {!isNullUndefined(video) && <video width="100%" controls poster={!isNullUndefined(videoPoster) ? videoPoster.file.url : ""}>
                                    <source src={isNullUndefined(video) ? "" : video.file.url} type="video/mp4"/>
                                    </video>}
                             
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className={styles.videosectionSidetext}>
                              {!isNullUndefined(videoSectionSideText) && ReactHtmlParser(videoSideHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
          <div className="container pt-1 pb-1 mt-3">

            <div className="row">
              <div className="col-12">
                {/* <img src={isNullUndefined(pageBanner) ? "" : pageBanner.file.url} style={{ width: '100% ', maxWidth: 'unset' }} /> */}
              </div>
            </div>
          </div>
           </div>
         
            <Footer/>
                {!isNullUndefined(video) && <div>
                                 <div className={`${styles.video_wrap} ${isActive ? `${styles.active_video}` : ""} `} >
                                   
                                    <div className={`${styles.video_content}  `} >
                                    <div className={styles.close_video}><Icon icon={ic_close} /></div>
                                    <video  height="" controls>
                                    <source src={isNullUndefined(video) ? "" : video.file.url} type="video/mp4"/>
                                    </video>
                                    </div>
                                </div>
                                </div>}
        </TemplateWrapper>
     );
}

export default VideoPdfTemplatePageNonUkHcp