import {navigate} from "gatsby"
import Axios from "axios"


export const retrieveUserDataFromAccessToken = async (tokenResult) => {
    if(!tokenResult){
      return null;
    }
  
    let {access_token} = tokenResult;
  
    const endpointUrl = `https://login.doccheck.com/service/oauth/user_data/?access_token=${access_token}&dc_oauth_format=json`;
  
    try {
      let results = await Axios.post(endpointUrl);
      return results;
    }catch(e){
      console.log(e);
      return null;
    }
  
}
  
export const isAccessTokenValid = async (accessToken) => {
    const endpointUrl = `https://login.doccheck.com/service/oauth/access_token/checkToken.php?access_token=${accessToken}`;
  
    try {
      let result = (await Axios.post(endpointUrl))?.data;
      return result.boolIsValid;
    }catch(e){
      console.log(e)
      return false;
    }
}
  
export const getNewTokens = async (oldTokenResult) => {
    if(!oldTokenResult){
      return null;
    }
  
    const {refresh_token} = oldTokenResult;
  
    const endpointUrl = `https://login.doccheck.com/service/oauth/access_token/?client_id=${process.env.GATSBY_DOCCHECK_LOGIN_ID}&client_secret=${process.env.GATSBY_DOCCHECK_CLIENT_SECRET}&refresh_token=${refresh_token}&grant_type=refresh_token`;
  
    try {
      let results = await Axios.post(endpointUrl);
      let newTokenResult = Object.assign({}, results.data);

      if(!("refresh_token" in newTokenResult)){
        newTokenResult.refresh_token = refresh_token;
      }

      localStorage.setItem("gatsby_doccheck_token_result", JSON.stringify(newTokenResult));

      return newTokenResult;
    }catch(e){
      console.log(e);
      return null;
    }
}
  
export const retrieveDocCheckAccessToken = async (code) => {
    let endpointUrl = `https://login.doccheck.com/service/oauth/access_token/?client_id=${process.env.GATSBY_DOCCHECK_LOGIN_ID}&client_secret=${process.env.GATSBY_DOCCHECK_CLIENT_SECRET}&code=${code}&grant_type=authorization_code`;
  
    try {
      let results = await Axios.post(endpointUrl);
      return results.data;
    }catch(e){
      console.log(e);
      return null;
    }
}

export const clearDoccheckFromLocalStorage = () => {
    localStorage.removeItem("gatsby_doccheck_temporary_code")
    localStorage.removeItem("gatsby_doccheck_token_result")
}

export const getCodeFromLocalStorage = () => {
  return localStorage.getItem("gatsby_doccheck_temporary_code");
}
  
export const enforceLoggedInCheckForGermanPrescribers = async (code) => {
    if(!code){
      let codeFromLocalStorage = getCodeFromLocalStorage();
      if(codeFromLocalStorage === null){
        clearDoccheckFromLocalStorage()
        navigate("/NonUkResidents/HCP/prescribers/german/doccheck");
        return false;
      }
      code = codeFromLocalStorage;
    }
  
    let tokenResult = await retrieveDocCheckAccessToken(code);
  
    if(!tokenResult){
      clearDoccheckFromLocalStorage()
      navigate("/NonUkResidents/HCP/prescribers/german/doccheck");
      return false;
    }

    //save in localStorage, for access in other pages
    localStorage.setItem("gatsby_doccheck_temporary_code", `${code}`);
    localStorage.setItem("gatsby_doccheck_token_result", JSON.stringify(tokenResult));

    if(window.location.pathname !== "/NonUkResidents/HCP/information-for-german-prescribers"){
        navigate(`/NonUkResidents/HCP/information-for-german-prescribers/?code=${code}`);
    }

    return true;
  
    // let loggedInUser = await retrieveUserDataFromAccessToken(tokenResult);
  
    // console.log("logged in user", loggedInUser);
  
    // if(!loggedInUser){
    //   navigate("/NonUkResidents/HCP/prescribers/german/doccheck");
    //   return false;
    // }
}
export const enforceLoggedInCheckForGermanPrescribersEfmody = async (code) => {
    if(!code){
      let codeFromLocalStorage = getCodeFromLocalStorage();
      if(codeFromLocalStorage === null){
        clearDoccheckFromLocalStorage()
        navigate("/NonUkResidents/HCP/prescribers/german-efmody/doccheck");
        return false;
      }
      code = codeFromLocalStorage;
    }
  
    let tokenResult = await retrieveDocCheckAccessToken(code);
  
    if(!tokenResult){
      clearDoccheckFromLocalStorage()
      navigate("/NonUkResidents/HCP/prescribers/german-efmody/doccheck");
      return false;
    }

    //save in localStorage, for access in other pages
    localStorage.setItem("gatsby_doccheck_temporary_code", `${code}`);
    localStorage.setItem("gatsby_doccheck_token_result", JSON.stringify(tokenResult));

    if(window.location.pathname !== "/NonUkResidents/HCP/information-for-german-prescribers-for-efmody"){
        navigate(`/NonUkResidents/HCP/information-for-german-prescribers-for-efmody/?code=${code}`);
    }

    return true;
  
    // let loggedInUser = await retrieveUserDataFromAccessToken(tokenResult);
  
    // console.log("logged in user", loggedInUser);
  
    // if(!loggedInUser){
    //   navigate("/NonUkResidents/HCP/prescribers/german/doccheck");
    //   return false;
    // }
}

export const shouldShowDoccheckLoginPrompt = async () => {
    let temporaryCode = localStorage.getItem("gatsby_doccheck_temporary_code");
    let tokenResult = localStorage.getItem("gatsby_doccheck_token_result");
    
    if(temporaryCode === null || tokenResult === null){
        return true;
    }

    //refresh the tokens
    let parsedTokenResult = JSON.parse(tokenResult);

    let reuseAccessToken = await isAccessTokenValid(parsedTokenResult.access_token);

    if(!reuseAccessToken){
        let newTokenResult = await getNewTokens(tokenResult);

        if(!newTokenResult){
            return true;
        }

        parsedTokenResult = Object.assign({}, newTokenResult);
    }

    return false;
}